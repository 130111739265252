import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Button, IconButton, Container, Grid } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Auth } from 'aws-amplify';
import InstanceControlCard from './instanceControlCard'
import CssBaseline from "@material-ui/core/CssBaseline";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  grid: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
  }
}));

function signOut() {
    Auth.signOut();
}

export default function BasicInterface() {
    const classes = useStyles();
    
    const instancesData = [
        { title: 'London', region:"eu-west-2", instance: 'i-0c60a48f01e9c7a2b', status: false, instanceAPI: 'https://lyiq3mipvb.execute-api.us-west-1.amazonaws.com', demoURL: "https://eu-west.commonground-demo.xyz" },
        { title: 'Experiment', region:"us-west-1", instance: 'i-0dd7a77003746f42c', status: false, instanceAPI: 'https://lyiq3mipvb.execute-api.us-west-1.amazonaws.com', demoURL: "https://exp.commonground-demo.xyz" },
        { title: 'N California', region:"us-west-1", instance: 'i-0630b903c6aad9275', status: false, instanceAPI: 'https://lyiq3mipvb.execute-api.us-west-1.amazonaws.com', demoURL: "https://us-west.commonground-demo.xyz" },
        { title: 'Hollywood-1 (GT)', region:"us-west-1", instance: 'i-0e192c20505b66756', status: false, instanceAPI: 'https://lyiq3mipvb.execute-api.us-west-1.amazonaws.com', demoURL: "https://hollywood-1.commonground-demo.xyz" },
        { title: 'Hollywood-2-US (Inferred)', region:"us-west-1", instance: 'i-03c29cb011d82ae8b', status: false, instanceAPI: 'https://lyiq3mipvb.execute-api.us-west-1.amazonaws.com', demoURL: "https://hollywood-2.commonground-demo.xyz" },
        { title: 'Hollywood-2-EU (Inferred)', region:"eu-west-1", instance: 'i-0b8dd0f0ac05527b1', status: false, instanceAPI: 'https://lyiq3mipvb.execute-api.us-west-1.amazonaws.com', demoURL: "https://hollywood-2-eu.commonground-demo.xyz" },
        { title: 'TrueSelf', region:"us-west-1", instance: 'i-0eecfbe7d8efc56bc', status: false, instanceAPI: 'https://lyiq3mipvb.execute-api.us-west-1.amazonaws.com', demoURL: "https://trueself.commonground-demo.xyz" },
        { title: 'Sydney', region:"ap-southeast-2", instance: 'i-031db84d7e32a3265', status: false, instanceAPI: 'https://lyiq3mipvb.execute-api.us-west-1.amazonaws.com', demoURL: "https://ap-southeast.commonground-demo.xyz" },
        { title: 'QA-Hollywood', region:"eu-west-1", instance: 'i-0c23130ec15b1ccd6', status: false, instanceAPI: 'https://lyiq3mipvb.execute-api.us-west-1.amazonaws.com', demoURL: "https://qa-hollywood.commonground-demo.xyz" },
        { title: 'QA-TrueSelf', region:"us-west-1", instance: 'i-0e23eafef45cb89f9', status: false, instanceAPI: 'https://lyiq3mipvb.execute-api.us-west-1.amazonaws.com', demoURL: "https://qa-trueself.commonground-demo.xyz" },
        { title: 'stag-pixel1', region:"eu-west-1", instance: 'i-0cc8a71f676d597f5', status: false, instanceAPI: 'https://lyiq3mipvb.execute-api.us-west-1.amazonaws.com', demoURL: "https://pixel1.stag.commonground-demo.xyz" },
        { title: 'QA-PixelStreaming-US', region:"us-west-1", instance: 'i-0cd80b82a57c18908', status: false, instanceAPI: 'https://lyiq3mipvb.execute-api.us-west-1.amazonaws.com', demoURL: "https://qa-pixelstreaming-us.commonground-demo.xyz" },
        { title: 'QA-PixelStreaming-EU', region:"eu-west-1", instance: 'i-03232a907f0ffb38d', status: false, instanceAPI: 'https://lyiq3mipvb.execute-api.us-west-1.amazonaws.com', demoURL: "https://qa-pixelstreaming-eu.commonground-demo.xyz" }
    ];

    return (
      <CssBaseline>
        <Container maxWidth="xl" disableGutters={true}>
            <AppBar position="static">
            <Toolbar>
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                <MenuIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>Demo Manager</Typography>
                <Button onClick={signOut}>
                    <Typography variant="h6">Logout</Typography>
                </Button>
            </Toolbar>
            </AppBar>
            <Grid container direction="row"  
                spacing={2} className={classes.grid}>
            {instancesData.map((value, index) => (
                <Grid key={index} xs={3} item>
                    <InstanceControlCard {...value} />
                </Grid>
            ))}
            </Grid>
        </Container>
        </CssBaseline>
    );
}
