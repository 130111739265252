import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Amplify from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import awsExports from "./aws-exports";
import TempInterface from "./BasicInterface";

Amplify.configure(awsExports);

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(2, 2),
    position: 'relative',
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  }
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://commonground-ai.com/">
        CommonGround
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

function App() {
  const classes = useStyles();

  return (
    <Container maxWidth="xl" component="main">
      <CssBaseline />
      <TempInterface />
      <footer className={classes.footer}>
        <Container maxWidth="xl" disableGutters={true}>
          <Copyright />
        </Container>
      </footer>
    </Container>
  );
}

export default withAuthenticator(App);
