import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import StopIcon from '@material-ui/icons/Stop';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CachedIcon from '@material-ui/icons/Cached';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: 400,

  },
  details: {

  },
  content: {

  },
  status: {

  },
  engine: {
    display: 'flex',
    alignItems: 'center',
  },
  cover: {
    width: 151,
  }
}));

let DemoTypes = {
  'ED_4' : "ED_4",
  'ED_1_on_1' : "ED_1_on_1",
} 
let DemoNames = {
  'ED_4' : "Multi-person",
  'ED_1_on_1' : "1 on 1",
} 
export default function InstanceControlCard(props) {
  const classes = useStyles();
  const { title, region, instance, state, instanceAPI, demoURL } = props;
  
  const [instanceStatus, setInstanceStatus] = useState(false);
  const [demoED4Status, setDemoED4Status] = useState(false);
  const [demoED1Status, setDemoED1Status] = useState(false);
  const [demoType, setDemoType] = useState('ED_4');
  const [guestPassword, setGuestPassword] = useState('');

  useEffect(() => {
    try {let intervalID;
      intervalID = setInterval(async () => {
        getInstanceStatus();
      }, 10000); // 30 sec
      return () => clearInterval(intervalID);
    } catch(e) {
      console.log(e);
    }

    
  });

  const getInstanceStatus = () => {
    axios.get(`${instanceAPI}/monitorPixelStream?region=${region}&instance=${instance}`).then((res) => {
      //console.log("status: " + res.data);
      const state = res.data;
      setInstanceStatus(state);
      if (state) getDemoStatus();
    }).catch(err => {
      setInstanceStatus(false);
    });
  };

  const toggleInstanceState = () => {
    if (instanceStatus) {
      axios.delete(`${instanceAPI}/startStopPixelStreamingServer?region=${region}&instance=${instance}`).then((res) => {
        //console.log("status: " + res.data);
        // const state = res.data;
        // setInstanceStatus(!state);
        setInstanceStatus(false);
        //if (state) getDemoStatus();
      }).catch(err => {
        // setInstanceStatus(false);
      });
    } else {
      axios.post(`${instanceAPI}/startStopPixelStreamingServer?region=${region}&instance=${instance}`).then((res) => {
        //console.log("status: " + res.data);
        // const state = res.data;
        // setInstanceStatus(state);
        // if (state) getDemoStatus();
      }).catch(err => {
        // setInstanceStatus(false);
      });
    }
  };

  const getDemoStatus = () => {
    console.log("Getting status",demoType,title)
    axios.post(`${instanceAPI}/instanceControl?instance=${instance}&action=status&demoType=${demoType}`).then((res) => {
      //console.log("status: " + res.data.status);
    
      if(res.data.demoType === DemoTypes.ED_4){
        if(demoED4Status !== res.data.status){
          console.log("Old demo status 4:",demoED4Status);
          console.log("New demo status 4:",res.data.status);
          setDemoED4Status(res.data.status)
        }
      }else if(res.data.demoType === DemoTypes.ED_1_on_1){
        if(demoED1Status !== res.data.status){
          console.log("Old demo status 1:",demoED1Status);
          console.log("New demo status 1:",res.data.status);
          setDemoED1Status(res.data.status)
        }
      }
    
      
    }).catch(err => {
      
    });
  };

const demoRestart = () => {
    axios.post(`${instanceAPI}/instanceControl?instance=${instance}&action=restart&demoType=${demoType}`).then((res) => {
        //console.log("status: " + res.data.status);
      
    }).catch(err => {
        
        
    });
};

const demoStart = () => {
  axios.post(`${instanceAPI}/instanceControl?instance=${instance}&action=start&demoType=${demoType}`).then((res) => {
      //console.log("status: " + res.data.status);
      
  }).catch(err => {
      
     
  });
};

const demoStop = () => {
    axios.post(`${instanceAPI}/instanceControl?instance=${instance}&action=stop&demoType=${demoType}`).then((res) => {
      //console.log("status: " + res.data.status);
     
    }).catch(err => {
    });
};

const generatePassword = () => {
  console.log("generatePassword");
  setGuestPassword('');
  axios.post(`${instanceAPI}/usersManager?instance=${instance}`).then((res) => {
    console.log("password: " + res.data.message);
    if (res.data.status) {
      setGuestPassword(res.data.message);
    }
  }).catch(err => {    
  });
};
const handleChange = (event) => {
  setDemoType(DemoTypes[event.target.value])
};

  return (
    <Card className={classes.root}>
        <CardContent className={classes.content}>
            <Typography component="h5" variant="h5" style={{ display: 'flex', alignItems: 'center' }}>
                <a target="_blank" rel="noopener noreferrer" href={demoURL} style={{textDecoration: 'none'}}>{title}</a>
                <FiberManualRecordIcon style={ instanceStatus ? { color: '#00D000' } : { color: 'red'}}/>
                <IconButton aria-label="start/stop" onClick={toggleInstanceState}>{instanceStatus ? <StopIcon /> : <PlayArrowIcon />}</IconButton>
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">{region}</Typography>

        {instanceStatus ? 
        (<div>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={demoType}
          onChange={handleChange}
        >
          <MenuItem value={DemoTypes.ED_4}>{DemoNames.ED_4}</MenuItem>
          <MenuItem value={DemoTypes.ED_1_on_1}>{DemoNames.ED_1_on_1}</MenuItem>
        </Select>
        <FormHelperText>Choose one of the available exprince demos</FormHelperText>
        <IconButton aria-label="restart/start" onClick={demoStart}><PlayArrowIcon /></IconButton>
        <Grid
                container
                direction="row"
                justify="left"
                alignItems="left"
          
              >
              <Typography variant="subtitle1">{DemoNames.ED_4}</Typography>
              <IconButton aria-label="restart/start" disabled={!demoED4Status} onClick={demoRestart}><CachedIcon /></IconButton>
              <IconButton aria-label="stop" disabled={!demoED4Status} onClick={demoStop}><StopIcon/></IconButton>
              </Grid>
              <Grid
                container
                direction="row"
                justify="left"
                alignItems="left"
            
              >
              <Typography variant="subtitle1">{DemoNames.ED_1_on_1}</Typography>
              <IconButton aria-label="restart/start" disabled={!demoED1Status} onClick={demoRestart}><CachedIcon /></IconButton>
              <IconButton aria-label="stop" disabled={!demoED1Status} onClick={demoStop}><StopIcon/></IconButton>
              </Grid>
         
        
            <Typography variant="subtitle1">Generate Password</Typography>
            <IconButton aria-label="generate" onClick={generatePassword}><CachedIcon /></IconButton>
            <Typography>{guestPassword}</Typography>
            </div>)
            : ''}
        </CardContent>
    
    </Card>
  );
}
